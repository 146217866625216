import React from "react";

const CheckboxInput = React.forwardRef((props, ref) => (
    <label className="checkbox-input">
        <div>
            <input
                type="checkbox"
                ref={ref}
                required={props.required}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
            />

            <span>
                {props.label}
            </span>
        </div>

        {props.valid === false && (
            <span role="alert">
                {props.errorText}
            </span>
        )}
    </label>
));

export default CheckboxInput;
