import React from "react";

const ProgressBar = ({
    donePercent,
    donePercentTitle,
    inProgressPercent,
    inProgressPercentTitle,
}) => {
    return (
        <p className="progress-bar">
            {donePercent > 0 && (
                <>
                    <span
                        className="done"
                        title={donePercentTitle}
                        style={{ width: `${donePercent}%` }}
                    >
                        &nbsp;
                    </span>

                    <span className="sr-only">
                        {donePercentTitle}
                    </span>
                </>
            )}

            {inProgressPercent > 0 && (
                <>
                    <span
                        className="in-progress"
                        title={inProgressPercentTitle}
                        style={{ width: `${inProgressPercent}%` }}
                    >
                        &nbsp;
                    </span>

                    <span className="sr-only">
                        {inProgressPercentTitle}
                    </span>
                </>
            )}
        </p>
    );
};

export default ProgressBar;