import React from "react";

import ProgressBar from "../../ProgressBar";

const TenantBeenCreatedProgressBar = ({ pastSeconds, totalSeconds }) => {
    const progressPercents = Math.round(pastSeconds / totalSeconds * 100);
    const minutesRemaining = Math.round((totalSeconds - pastSeconds) / 60);

    return (
        <div className="trial-tenant-creation-progress">
            <ProgressBar
                donePercentTitle="Frends tenant creation progress"
                donePercent={progressPercents}
            />

            <div className="is-flex is-justify-content-space-between">
                <p className="is-black">{`${minutesRemaining} minutes remaining`}</p>
                <p className="is-black">{`${progressPercents}%`}</p>
            </div>
        </div>
    );
};

export default TenantBeenCreatedProgressBar;