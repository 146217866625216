import React from "react";

import useTrialAuthClaimCheck, { CREATE_PATH } from "../../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../../components/profile/shared/PageContentContainer";
import CreateTenantContent from "../../../components/profile/trial/CreateTenantContent";

import { pushSAEvent } from "../../../helpers";

export default ({ location }) => {
    const loading = useTrialAuthClaimCheck(CREATE_PATH);

    const menuItems = [
        {
            path: CREATE_PATH,
            text: 'Trial',
            open: true,
        },
    ];

    pushSAEvent('visit_profile_trial_create_tenant');

    return (
        <PageContentContainer
            title="Your profile | Trial"
            location={location}
            content={<CreateTenantContent />}
            menuItems={menuItems}
            loading={loading}
        />
    );
};