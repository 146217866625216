
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import ContentContainer from "../shared/ContentContainer";
import ContentTitle from "../shared/ContentTitle";
import ContentSubTitle from "../shared/ContentSubTitle";
import ContentParagraph, { PlanActivationContentParagraph } from "../shared/ContentParagraph";
import { PURCHASE_PATH } from "../shared/AuthClaimCheckHook";
import ContentLink, { ContentLinkExternal } from "../shared/ContentLink";
import { PageContentLoadingIndicator } from "../shared/ContentLoadingIndicator";
import InformationContentGrid from "../shared/InformationContentGrid";
import KPICard from "../shared/KPICard";
import TenantUsersGrid from "../shared/TenantUsersGrid";

import TenantBeenCreatedProgressBar from "./CreatedProgressBar";

import { getCookie } from "../../../helpers";

export const TRIAL_API_BASE_URL = 'https://frends-internal-cloudprod-agent.frendsapp.com/api/trial/v1';

export const useTrialDataApi = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [tenantStatus, setTenantStatus] = React.useState();

    let controller;
    const getTenantStatus = async () => {
        try {
            controller = new AbortController();
            const token = await getAccessTokenSilently();
            const { data } = await axios.get(
                `${TRIAL_API_BASE_URL}/data`,
                {
                    signal: controller.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );
            setTenantStatus(data);
        } catch (e) { }
    }

    React.useEffect(() => {
        return () => {
            if (controller) controller.abort();
        }
    }, []);

    return { getTenantStatus, tenantStatus };
};

const StatusContent = ({
    tenantStatus,
    getTenantStatus
}) => (
    <>
        {tenantStatus === undefined && (
            <PageContentLoadingIndicator />
        )}

        {(tenantStatus === null || (tenantStatus?.TrialOrdered && !tenantStatus?.TrialDelivered)) && (
            <TenantBeenCreatedContent
                trialStatus={tenantStatus?.TrialStatus}
                trialStatusTimestamp={tenantStatus?.TrialStatusTimestamp}
                trialOrderedTimestamp={tenantStatus?.TrialOrdered}
                onPollTenantStatus={() => getTenantStatus()}
            />
        )}

        {(tenantStatus?.TrialOrdered && tenantStatus?.TrialDelivered) && (
            <TenantInformationContent tenantStatus={tenantStatus} />
        )}
    </>
);


const TenantBeenCreatedContent = ({
    trialStatus,
    trialStatusTimestamp,
    trialOrderedTimestamp,
    onPollTenantStatus
}) => {
    const isAcronisTrial = getCookie('trial_type') === 'acronis';
    const totalSeconds = isAcronisTrial ? 2100 : 780;

    const getSecondsSinceTrialProvisionStatusUpdate = () => {
        if (!trialStatus) {
            return 0;
        }
        if (trialStatus === 'Tenant Ready') {
            return totalSeconds;
        }

        const trialStatusTimeAsDate = new Date(trialStatusTimestamp);
        const now = new Date();
        const tenantProvisionedStatusSeconds = 360;
        const secondsSinceTrialStatusUpdate = ((now.getTime() - trialStatusTimeAsDate.getTime()) / 1000) + (trialStatus === 'Tenant Ordered' ? 0 : tenantProvisionedStatusSeconds);
        return Math.min(secondsSinceTrialStatusUpdate, (trialStatus === 'Tenant Ordered' ? tenantProvisionedStatusSeconds : totalSeconds));
    };

    const getPastSeconds = () => {
        const secondsSinceStatusUpdated = getSecondsSinceTrialProvisionStatusUpdate();
        return secondsSinceStatusUpdated <= totalSeconds ? secondsSinceStatusUpdated : totalSeconds;
    };

    const [pastSeconds, setPastSeconds] = React.useState(getPastSeconds());

    const isAutomaticTrialCreationOngoing = () => {
        if (!trialOrderedTimestamp) {
            return true;
        }

        if (trialStatus !== 'Tenant Ordered' && trialStatus !== 'Tenant Provisioned' && trialStatus !== 'Tenant Ready') {
            return false;
        }

        const now = new Date();
        const secondsSinceTrialOrdered = ((now.getTime() - new Date(trialOrderedTimestamp).getTime()) / 1000);
        const maxInSeconds = isAcronisTrial ? 2700 : 1200;
        return (secondsSinceTrialOrdered < maxInSeconds);
    };

    let updateCreationEstimateInterval;
    const updateCreationEstimate = () => {
        updateCreationEstimateInterval = !updateCreationEstimateInterval && setInterval(() => {
            const newPastSeconds = pastSeconds + 5;
            if (newPastSeconds <= totalSeconds) {
                setPastSeconds(newPastSeconds);
            }
        }, [5000]);
    };

    React.useEffect(() => {
        updateCreationEstimate();

        return () => clearInterval(updateCreationEstimateInterval);
    }, [pastSeconds]);

    let pollTenantStatusInterval;
    const pollTenantStatus = () => {
        pollTenantStatusInterval = !pollTenantStatusInterval && setInterval(() => {
            onPollTenantStatus();
        }, [10000]);
    };

    React.useEffect(() => {
        pollTenantStatus();

        return () => clearInterval(pollTenantStatusInterval);
    }, []);

    return (
        <ContentContainer>
            <ContentTitle>
                Almost there!
            </ContentTitle>

            {isAutomaticTrialCreationOngoing() ? (
                <>
                    <ContentSubTitle>
                        Your Frends tenant is currently being created.
                    </ContentSubTitle>
                    <ContentParagraph>
                        You'll be redirected to the trial page and receive an email from us when everything is ready.
                    </ContentParagraph>
                    <TenantBeenCreatedProgressBar
                        pastSeconds={pastSeconds}
                        totalSeconds={totalSeconds}
                    />
                    <ContentParagraph>
                        While you are waiting feel free to check out following resources to get you started:
                        <br />
                        <a href="https://docs.frends.com/en/" target="_blank" className="is-purple">Frends Documentation</a>
                        <br />
                        <a href="/services/frends-academy" target="_blank" className="is-purple">Frends Academy</a>
                        <br />
                        <br />
                        You can contact us at <a href="mailto:support@frends.com" className="is-purple">support@frends.com</a>.
                    </ContentParagraph>
                </>
            ) : (
                <>
                    <ContentSubTitle>
                        Oh no! Your automatic trial provision process has been halted.
                    </ContentSubTitle>
                    <ContentParagraph>
                        You can contact us at <a href="mailto:support@frends.com" className="is-purple">support@frends.com</a>.
                    </ContentParagraph>
                </>
            )}
        </ContentContainer>
    );
};

const TenantInformationContent = ({ tenantStatus }) => {
    const isPurchasePending = () => tenantStatus.TrialStatus === 'Purchase Pending';

    const getDaysLeftOfTrial = () => {
        const delivered = new Date(tenantStatus.TrialDelivered);
        const dayInMillis = 86400000;
        const trialEndMillis = delivered.getTime() + 30 * dayInMillis;
        const nowMillis = new Date().getTime();
        const daysLeft = Math.round((trialEndMillis - nowMillis) / dayInMillis);
        return 0 <= daysLeft ? daysLeft : 'X';
    };

    const daysLeftOfTrial = getDaysLeftOfTrial();

    const pointTenantURLToLogin = (url) => {
        if (!url) {
            return '';
        }
        return url + 'api/account/signin/JaHQjesdzTNM0yURJMfLqeGIPu3BHCkX?ReturnUrl=%2Fproducttours';
    }

    const isTrialOngoing = () => (daysLeftOfTrial !== 'X');

    return (
        <ContentContainer>
            <ContentTitle>
                Trial
            </ContentTitle>

            {isPurchasePending() && (
                <PlanActivationContentParagraph>
                    The paid subscription is not yet active. The contract sent to your email has not been signed yet. Please sign it to get the subscription activated.
                </PlanActivationContentParagraph>
            )}

            <InformationContentGrid alignItemsEnd>
                <>
                    <KPICard
                        number={daysLeftOfTrial}
                        text={isTrialOngoing() ? 'Days left in trial' : 'Your trial has expired'}
                        type={isTrialOngoing() ? '' : 'plain'}
                    />

                    <div
                        className="is-flex is-flex-direction-column"
                        style={{ gap: '1.1rem' }}
                    >
                        <ContentLinkExternal href={pointTenantURLToLogin(tenantStatus.TrialURL)}>
                            go to tenant
                        </ContentLinkExternal>

                        {!isPurchasePending() && (
                            <ContentLink href={PURCHASE_PATH}>
                                purchase frends
                            </ContentLink>
                        )}
                    </div>
                </>
            </InformationContentGrid>

            <TenantUsersGrid
                apiBaseUrl={TRIAL_API_BASE_URL}
                canUsersBeInvited={isTrialOngoing()}
                title="Trial users"
            />
        </ContentContainer>
    );
};

export default StatusContent;
