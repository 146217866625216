
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import ContentContainer from "../shared/ContentContainer";
import ContentTitle from "../shared/ContentTitle";
import ContentSubTitle from "../shared/ContentSubTitle";
import ContentParagraph from "../shared/ContentParagraph";
import { SubmitButton } from "../shared/ContentButton";
import TextInput from "../shared/TextInput";
import CheckboxInput from "../shared/CheckboxInput";

import { TRIAL_API_BASE_URL } from "./StatusContent";

import { getCookie } from "../../../helpers";

const CreateTenantContent = () => {
    const { getAccessTokenSilently } = useAuth0();
    const personRoleValues = ['Developer', 'Operations', 'IT Director or Manager', 'Team Leader', 'Project Manager', 'Sales Manager', 'CxO', 'Founder'];

    const nameInputRef = React.useRef(null);
    const personRoleInputRef = React.useRef(null);
    const termsInputRef = React.useRef(null);

    const [tenantName, setTenantName] = React.useState('');
    const [tenantNameValid, setTenantNameValid] = React.useState(undefined);
    const [personRole, setPersonRole] = React.useState();
    const [personRoleValid, setPersonRoleValid] = React.useState(undefined);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [termsAcceptedValid, setTermsAcceptedValid] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    const handleTenantNameChange = (evt) => {
        const newTenantNameValue = evt.target.value;
        setTenantName(newTenantNameValue);
        setTenantNameValid(true);
    };

    const handlePersonRoleChange = (evt) => {
        const newPersonRoleValue = evt.target.value;
        setPersonRole(newPersonRoleValue);
        setPersonRoleValid(true);
    };

    const handleTermsAcceptedChange = () => {
        setTermsAccepted((prev) => !prev);
        setTermsAcceptedValid((prev) => !prev);
    };

    const handleSetTenantNameInvalid = () => {
        setTenantNameValid(false);
        nameInputRef.current.focus();
        setLoading(false);
    };

    const handleSetPersonRoleInvalid = () => {
        setPersonRoleValid(false);
        personRoleInputRef.current.focus();
        setLoading(false);
    };

    let controller;
    const handleCreateTenantClick = async (evt) => {
        evt.preventDefault();

        if (loading) {
            return;
        }

        if (!nameInputRef.current.checkValidity() || !tenantNameValid) {
            handleSetTenantNameInvalid();
            return;
        }

        if (!personRole) {
            handleSetPersonRoleInvalid();
            return;
        }

        if (!termsAcceptedValid) {
            setTermsAcceptedValid(false);
            termsInputRef.current.focus();
            setLoading(false);
            return;
        }

        setLoading(true);

        try {
            controller = new AbortController();
            const token = await getAccessTokenSilently();

            const { data: checkNameData } = await axios.get(
                `${TRIAL_API_BASE_URL}/checkname/${tenantName}`,
                {
                    signal: controller.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );

            if (checkNameData.Status !== 'FREE') {
                handleSetTenantNameInvalid();
                return;
            }

            const trialData = getCookie('trial_type') === 'acronis' ? 'Acronis' : 'Frends';
            controller = new AbortController();
            const { data: createData } = await axios.post(
                `${TRIAL_API_BASE_URL}/create`,
                {
                    TrialTenantName: tenantName,
                    TrialContactPersona: personRole,
                    TrialData: trialData,
                },
                {
                    signal: controller.signal,
                    headers: { Authorization: `Bearer ${token}` }
                },
            );

            if (createData.Status !== 'CREATED') {
                handleSetTenantNameInvalid();
                return;
            }

            window.location.assign(`${window.location.origin}/profile/trial`); // hard refresh to get auth0 sdk to update id claims to include trial tenant name
        } catch (e) {
            handleSetTenantNameInvalid();
        }
    };

    React.useEffect(() => {
        return () => {
            if (controller) controller.abort();
        }
    }, []);

    return (
        <ContentContainer>
            <ContentTitle>
                Get trial!
            </ContentTitle>
            <ContentSubTitle>
                Select your Frends tenant name
            </ContentSubTitle>
            <ContentParagraph>
                This will be the address you use to login to your Frends, usually many customers select their company name. Note that changing the name later requires contact to <a href="mailto:support@frends.com" className="is-purple">support@frends.com</a>. The tenant name needs to be alphanumeric, lowercase and contain 3 to 13 characters. If the tenant name is not available please try another one.
            </ContentParagraph>

            <form className="trial-form is-flex is-flex-direction-column">
                <TextInput
                    ref={nameInputRef}
                    required
                    pattern="^[a-z0-9]+(?:-[a-z0-9]+)*$"
                    maxLength={13}
                    autoFocus
                    type="text"
                    label="Tenant name"
                    placeholder="tenant-name"
                    name="tenantName"
                    caption=".frendsapp.com"
                    value={tenantName}
                    onChange={handleTenantNameChange}
                    valid={tenantNameValid}
                    errorText="Tenant name is unavailable. Please try another name. The tenant name must be alphanumeric, lowercase and contain 3 to 13 characters."
                    disabled={loading}
                />

                <fieldset>
                    <legend className="is-black size-18 mb-1">
                        Please select which role best describes you
                    </legend>

                    <div className="radio-buttons is-justify-content-start">
                        {personRoleValues.map((r, i) => (
                            <div key={i} className="radio-button" data-radiobutton-checked={r === personRole}>
                                <label>
                                    <input
                                        ref={i === 0 ? personRoleInputRef : null}
                                        type="radio"
                                        value={r}
                                        checked={r === personRole}
                                        onChange={handlePersonRoleChange}
                                    />

                                    <span>{r}</span>
                                </label>
                            </div>
                        ))}
                    </div>

                    {personRoleValid === false && (
                        <span role="alert">
                            Role is required
                        </span>
                    )}
                </fieldset>

                <CheckboxInput
                    ref={termsInputRef}
                    required
                    checked={termsAccepted}
                    onChange={handleTermsAcceptedChange}
                    label={<>I confirm that I have read and agree to the <a href="/legal/terms-of-service" target="_blank">terms of service</a>.</>}
                    valid={termsAcceptedValid}
                    errorText="Please confirm before continuing"
                    disabled={loading}
                />

                <SubmitButton
                    color="is-green"
                    loading={loading}
                    disabled={loading}
                    onClick={(e) => handleCreateTenantClick(e)}
                >
                    Get trial
                </SubmitButton>
            </form>
        </ContentContainer>
    );
};

export default CreateTenantContent;
